/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Kompletny system ścienny musi obejmować także elementy murowe do wznoszenia ścian działowych. W dzisiejszym budownictwie normą są przegrody wewnętrzne niekonstrukcyjne o grubości 10 cm, pustaki oraz bloczki Termo 10, które wpisują się w ten standard."), "\n", React.createElement(_components.p, null, "Termo 10 to idealne dopełnienie systemu ściennego bazującego na pustakach AKU. W zależności od potrzeb, możliwe jest wykonanie ściany działowej pełnej, która będzie charakteryzować się dobrą izolacyjnością akustyczną, lub otworowanej, która z kolei w mniejszym stopniu obciąży strop. Oba rozwiązania cechuje niski opór dyfuzyjny, co przekłada się na sprawne pobieranie i oddawanie wilgoci z i do pomieszczenia. Jest to niezwykle istotne, by stworzyć w domu lub mieszkaniu zdrowy mikroklimat. Wznoszenie ścian działowych przyśpieszają dodatkowo dedykowane do przegród niekonstrukcyjnych nadproża NLC."), "\n", React.createElement("table", {
    class: "table-section align-left"
  }, React.createElement("tr", null, React.createElement("th", {
    colspan: "2"
  }, "Bloczek śceinny Termo 10")), React.createElement("tr", null, React.createElement("td", null, "Wymiary"), React.createElement("td", null, "Długość: 495 mm ", React.createElement("br"), " Szerokość: 105 mm", React.createElement("br"), " Wysokość: 240 mm", React.createElement("br"), "Kategoria odchyłek wymiarów: D1")), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przewodzenia ciepła ", React.createElement("i", null, "I")), React.createElement("td", null, "0.26 ", React.createElement("i", null, "W/m*K"))), React.createElement("tr", null, React.createElement("td", null, "Opór cieplny ", React.createElement("i", null, "R")), React.createElement("td", null, "0.385 ", React.createElement("i", null, "m", React.createElement("sup", null, "2"), "/K*W"))), React.createElement("tr", null, React.createElement("td", null, "Współczynnik przenikania ciepła ", React.createElement("i", null, "U")), React.createElement("td", null, "1.402 ", React.createElement("i", null, "W/m", React.createElement("sup", null, "2"), "*K")))), "\n", React.createElement(_components.p, null, "Pustaki i bloczki Termo 10 posiadają certyfikat CE i podlegają stałej kontroli właściwości podczas produkcji. Deklarowane parametry zostały przedstawione w tabeli. Zachęcamy też do zapoznania się z kartą techniczną oraz katalogiem naszych pustaków i bloczków keramzytobetonowych. Projektantów zapraszamy natomiast do kontaktu – udostępnimy niezbędne dane i służymy pomocą w procesie projektowania z elementów na bazie keramzytu i keramzytobetonu."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
